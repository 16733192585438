@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body {
    margin: 0;
    // Roboto
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    // @apply bg-primary;
    background: linear-gradient(135deg, rgb(17 24 39) 0%, rgb(12 74 110) 100%);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

button {
    @apply bg-tertiary;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: 200ms;

    &:hover {
        @apply bg-secondary;
    }
}

input[type='text'] {
    @apply rounded-md text-black;
}

// Scroll bars
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0.375rem;
}

::-webkit-scrollbar-thumb {
    @apply bg-tertiary;
    border-radius: 0.375rem;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.modal-overlay {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.5);
}

.visible {
    visibility: visible !important;
    opacity: 1 !important;
}

.foldable {
    transition: 200ms;
    max-height: 500px;
    overflow: hidden;

    & .foldable-content {
        transition: 200ms;
        position: relative;
        opacity: 1;
    }
}

.foldable.folded {
    max-height: 20px;

    & .foldable-content {
        opacity: 0;
        // unselectable
        pointer-events: none;
    }
}

.foldable .title {
    margin-left: 4px;
}

.page-title {
    view-transition-name: page-title;
}

.folder-view {
    view-transition-name: folder-view;
}

::view-transition-new(*),
::view-transition-old(*) {
    // animation-duration: 1s;
}


::view-transition-old(folder-view),
::view-transition-new(folder-view) {
    /* Prevent the default animation,
  so both views remain opacity:1 throughout the transition */
    // animation: none;
    /* Use normal blending,
  so the new view sits on top and obscures the old view */
    mix-blend-mode: normal;
    /* Make the height the same as the group,
  meaning the view size might not match its aspect-ratio. */
    height: 100%;
    /* Clip any overflow of the view */
    overflow: clip;
}

/* The old view is the thumbnail */
::view-transition-old(folder-view) {
    /* Maintain the aspect ratio of the view,
  by shrinking it to fit within the bounds of the element */
    object-fit: contain;
}

/* The new view is the full image */
::view-transition-new(folder-view) {
    /* Maintain the aspect ratio of the view,
  by growing it to cover the bounds of the element */
    object-fit: cover;
}