#graphFolderContainer {
    @apply bg-secondary rounded-md;

    display: flex;
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: max-content;
    height: calc(100% - 2rem);
    padding: 1rem;
    z-index: 100;
}

.circle {
    border-radius: 50%;
}

.graph-options {
    @apply bg-secondary rounded-md;
    padding: 8px;
    min-width: 200px;
}
