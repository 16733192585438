a {
    color: rgb(24, 122, 179);
    text-decoration: underline;

    &:hover {
        color: lighten($color: rgb(24, 122, 179), $amount: 20);
        text-decoration: underline;
    }
}

#editPageContainer {
    display: grid;
    grid-template-columns: 1fr 60vw 1fr;
}

@media (max-width: 1380px) {
    #editPageContainer {
        grid-template-columns: 80vw 1fr;

        & > :first-child {
            display: none;
        }
    }
}

.stats {
    padding: 4px;
    border-radius: 0.375rem 0 0 0;
}
