.folder {
    position: relative;
    min-height: 1.75rem;
    overflow: hidden;

    &>h1 {

        // Display an arrow after the folder name using border
        &:after {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid white;
            margin-left: 5px;
            margin-bottom: 3px;
            transition: transform 0.3s ease;
            transform: rotate(90deg);
        }
    }

    &>.dirs {

        // Notes in the folder
        &>li {
            position: relative;

            &:before {
                position: absolute;
                content: '';
                display: inline-block;
                width: 1rem;
                height: 99%;
                top: -50%;
                left: -1.75rem;
                border-bottom: 1px solid white;
                border-left: 1px solid white;
            }

            &:first-child:before {
                top: -10%;
                height: 70%;
            }

        }
    }


    &>ul,
    &>ul * {
        transition: 300ms linear;
    }


    // Me when chess
    &.folded {

        // Change the borders to point down
        &>h1:after {
            transform: rotate(0deg);
        }


        &>ul,
        &>ul * {
            opacity: 0;
            margin-top: -25px;
            visibility: hidden;
        }
    }
}

.file {
    &>svg {
        opacity: 0;
        margin-left: -.5rem;
        transition: 300ms ease;
    }

    &>a:hover {
        cursor: pointer;

        &+svg {
            margin-left: .5rem;
            opacity: 1;
        }
    }
}