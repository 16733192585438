#previewContainer {
    view-transition-name: preview-container;

    //     &>div>h1 {
    //         view-transition-name: preview-title;
    //     }
}

.tooltip {
    display: none;
    position: absolute;
    @apply bg-tertiary rounded-md p-4;
}

.tooltip-content {
    overflow: auto;
    max-width: 30vw;
    max-height: 30vh;
}