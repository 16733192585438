.tab-container {
    display: flex;
    gap: 10px;
    border-bottom: 1px solid rgb(107 114 128);

    & > .tab {
        padding: 12px 24px;
        // background: #111213;
        border: 1px solid transparent;
        transform: translate(0, 1px);
        cursor: pointer;

        position: relative;

        & > ::after {
            content: '';
            position: absolute;
            bottom: -1px; // Account for border
            left: 0;
            right: 0;
            height: 1px;
            transform: scaleX(1.1);
            border-bottom: 1px solid rgb(107 114 128);
        }

        & > :hover {
            color: #bbb;
        }
    }

    & > .active-tab {
        border-top: 1px solid rgb(107 114 128);
        border-right: 1px solid rgb(107 114 128);
        border-left: 1px solid rgb(107 114 128);
        border-bottom: 1px solid;
        @apply border-b-primary;
        border-radius: 0.25rem 0.25rem 0 0;

        & > ::after {
            border: transparent;
        }
    }
}

.settings-page {
    display: flex;
    flex-direction: column;

    height: 100%;
}

.settings-page-container {
    height: 100%;
    width: 100%;
    margin: 32px auto;

    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}

.settings-tab {
    @apply bg-secondary;

    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 24px;
    overflow-y: auto;

    border: 1px solid rgb(107 114 128);
    border-radius: 0.375rem;

    & h1 {
        font-size: 48px;
        border-bottom: 1px solid rgb(107 114 128);
        text-align: center;
    }
}

.settings-category {
    padding: 24px 0;
    & h2 {
        font-size: x-large;
        font-weight: bold;
        padding: 4px;
        border-radius: 0.125rem;

        &:hover {
            background: rgb(43, 57, 77);
        }
    }
}

.settings-config {
    padding: 4px;
    border-radius: 0.125rem;

    &:hover {
        background: rgb(43, 57, 77);
    }

    & h3 {
        font-weight: bold;
        color: #fff;
    }

    & p {
        color: #bbb;
        padding: 4px 0;
    }

    &.config-button {
    }

    &.config-toggle {
    }
}
