#tabsContainer {
    height: 100%;
    width: 100vw;
    display: grid;
    grid-template-rows: min-content min-content minmax(0, auto);
}

.tabBar {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 5px;
    }
}

.tabHeader {
    @apply border-tertiary border-2 rounded-t-md;
    padding: 3px 10px;
    // height: 28px;
    margin-top: auto;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    position: relative;

    @apply bg-secondary;


    &>.close {
        margin-left: 10px;
        font-size: x-large;
    }

    &:hover {
        @apply bg-tertiary;
    }

    &.active {
        padding-top: 10px;
        background-color: rgb(51 61 75);
    }

    &>h1 {
        margin-top: auto;
    }
}

.tab {
    &.inactive {
        display: none;
    }
}