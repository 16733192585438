.editable {
    all: unset;
    margin: 0;
    padding: 0;
    margin: 1px 0;
    background-color: none;
    height: min-content;
    font-weight: bold;

    &:hover,
    &:focus {
        color: black;
        margin: 0;
        background-color: #f5f5f5;
        border: 1px solid black;
        border-radius: 5px;
        font-weight: normal;
    }
}

.fakeSpan {
    position: absolute;
    left: -9999px;
    display: inline-block;
    min-width: 6em;
}